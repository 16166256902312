import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { PageLoaderFallback } from '../../layout/PageLoaderFallback';

const Stillinger = loadable(
  () => import(/* webpackPrefetch: true */ './Stillinger'),
  {
    fallback: <PageLoaderFallback />,
  }
);

const Stilling = loadable(
  () => import(/* webpackPrefetch: true */ './Stilling'),
  {
    fallback: <PageLoaderFallback />,
  }
);

const NoMatch = loadable(
  () => import(/* webpackPrefetch: true */ '../404Old'),
  {
    fallback: <PageLoaderFallback />,
  }
);

export const Stillingsannonse: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/stillinger" component={Stillinger} />
      <Route exact path="/stillinger/:slug" component={Stilling} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default Stillingsannonse;
